import server from '@/api'

export function apply(param) {
    return server.supplier.apply(param)

}

export function checkSupplier(param) {
    return server.supplier.checkSupplier(param)

}