<template>
  <div style="margin: 40px auto 0; width: 1200px; background: #fff">
    <div v-if="supplierStatus === 0">
      <div class="doc-container">
        <div class="title">供应商交易规则</div>
        <div class="article">
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>总章</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b>1.<span>【特别提示】</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>本供应商交易规则（以下简称</span>“<span>本规则</span><span>”</span><span>）系佛山南海铝拍拍科技有限公司为规范供应商在使用铝拍拍平台服务或在铝拍拍平台销售商品的行为而制定，构成供应商与</span>佛山南海铝拍拍科技有限公司<span>以及供应商与买家之间就利用铝拍拍平台达成商品交易项下权利义务关系的具体约定，具有法律约束力。</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>供应商应认真阅读并遵守本规则及其他任何适用的平台规则。</span><b><u><span>供应商务必审慎阅读、充分理解全部平台规则的各项条款内容，特别是免除或者限制佛山南海铝拍拍科技有限公司责任的条款、争议解决和法律适用条款。</span></u></b><span>前述条款可能以加粗字体和加下划线显示，应重点阅读。</span><b><u><span>除非供应商已阅读并接受本规则和其他平台规则的所有条款，否则供应商无权使用铝拍拍平台服务或通过铝拍拍平台销售商品。供应商使用铝拍拍平台即视为供应商已充分理解并同意接受本规则和其他平台规则的约束。</span></u></b><b><u>&nbsp;</u></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b>2.<span>【定义】</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>除本规则另有规定外，本规则中提及的术语应当与《铝拍拍平台规则总则》</span><span>中定义了的术语具有相同的含义。</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b>3.<span>【</span></b><b>供应商规则</b><b><span>】</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><u><span>供应商系直接向买家销售商品，佛山南海铝拍拍科技有限公司不参与供应商和买家之间的商品买卖关系，不参与商品交易和货物所有权流转及发票的开具的过程，而仅作为交易的促成方，向买卖双方提供互联网信息服务及电子商务平台服务，以促成买家与卖</span>f<span>家之间直接的商品买卖关系的达成。</span></u></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b>4.<span>【免责声明】</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><u><span>供应商充分理解并同意：佛山南海铝拍拍科技有限公司并不参与买家和供应商直接达成的买卖关系，任何买卖关系项下产生的争议，买家有权直接向供应商主张权利，并由供应商承担其相对应的法律责任。虽有前述，供应商有义务配合铝拍拍平台处理买家与供应商之间交易纠纷的解决。</span></u></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>第一节</span></b><b>&nbsp;</b><b><span>挂货</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>5</span></b><b>.<span>【店铺开设资格】</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>5.1</span><span>供应商</span>在店铺<span>管理模块下开设店铺，通过系统审核后，方可在店铺</span>挂货<span>销售。个人用户及未经认证的企业用户无权申请开设店铺</span>。<span>在店铺管理模式下开设的店铺属于普通订单模式</span>。
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>5.2</span><b>.<span>【店铺开设流程】</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>用户开设店铺的流程具体如下：</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>（</span><span>1</span><span>）</span></b><b>&nbsp;</b><span>用户根据</span><u><span>《用户注册协议》</span></u><span>完成相应的企业用户注册；</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>（</span><span>2</span><span>）</span></b><span>用户上传企业营业执照、授权委托书，并经认证成为企业用户；</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>（</span><span>3</span><span>）</span></b><span>用户确认并同意相关供应商交易规则；</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>（</span><span>4</span><span>）</span></b><b>&nbsp;</b><span>用户申请开设店铺，提交店铺名称、店铺</span>LOGO<span>原图，并选择店铺类型；</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>（</span><span>5</span><span>）</span></b><span>用户须保证开设店铺时所提供申请材料的真实、完整、准确、合法有效。反之，用户需承担因此引起的相应责任及后果，并且</span>柏<span>斯基保留关闭用户所开设店铺的权利。</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>5.3</span><span>【</span><b>店铺等级</b>】
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            店铺等级划分及权限与供应商等级相同。铝拍拍平台每月月底检查店铺运营指标，如不符合相关的运营指标，则下月初该级店铺即会被下降等级。
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>5.4</span>&nbsp;<span>若用户根据《用户注册协议》或《供应商评级规则》而被取消供应商资质（如账户被注销或用户被取消挂货资格），则该等用户开设的店铺将会被一并关闭。</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>5.5</span>.<span>【用户义务】</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            用户在开设店铺后进行交易时，应当遵守国家法律法规及铝拍拍平台规则，遵守诚信原则及公平原则，并接受佛山南海铝拍拍科技有限公司的监管。如用户违反本规定、铝拍拍平台规则或国家法律法规的，佛山南海铝拍拍科技有限公司有权对该等用户按照《用户注册协议》或其他可适用的铝拍拍平台规则的相关规定采取措施。如由于用户的行为造成佛山南海铝拍拍科技有限公司损失的，用户应当进行相应赔偿。
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>6</span></b><b>.<span>【</span></b><b>可挂货</b><b><span>时间】</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            供应商默认的营业时间（一般为工作日北京时间11:00—17:00<span>）或者供应商根据平台选项目自行设置的其他营业时间</span>，<span>铝拍拍平台对供应商的挂货时间有特殊规定的，以铝拍拍平台发布的关于可</span>上架<span>时间的公告为准。</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>7</span></b><b>.<span>【</span></b><b>挂货</b><b><span>要求】</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>除平台规则另有约定外，供应商在铝拍拍平台挂货应当遵守包括但不限于以下规定：</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>（</span>1<span>）供应商必须保证所有在铝拍拍平台挂货的商品信息的时效性、有效性、真实性、合法性和准确性等，以及挂货商品本身的适售性、适用于特定用途性和非侵权性。</span><b><u><span>若相较于供应商实际交付的商品，供应商在铝拍拍平台上对所挂商品信息存在任何错误、虚假、不一致或不完整的情况的，应当承担包括退款退货以及平台规则规定的其他违约责任（无论该等错误、虚假、不一致或不完整是否事实上减损了买家或佛山南海铝拍拍科技有限公司的权利）</span></u></b><span>；</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>（</span>2<span>）供应商挂货时，需确保商品具有完备的产品质保书</span>、<span>质量证明书或与商品信息陈述相符的质量保障，对于铝拍拍平台系统已成功对接质量信息的商品，若供应商展示的质量信息与产品质保书或质量证明书内容不一致的，以产品质保书或质量证明书中记载的内容为准，如供应商所售商品无法受理质量异议的，必须清晰完整地阐明不能受理质量异议的具体范围、程度、原因（包括但不限于质保期限届满）等，否则供应商应当承担相应商品的质保义务；</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>（</span>3<span>）供应商需对其就挂货商品填写或设定的相关商品信息充分负责，除本规则另有约定外，一旦买家根据供应商挂货商品的信息下单的，买卖双方即达成合意，供应商不得单方面变更其设定的商品属性，</span><b><u><span>特别是，供应商充分理解</span></u></b><b><u><span>铝</span></u></b><b><u><span>材市场存在价格波动，供应商应当慎重设定其挂货商品的价格并为该等标价的法律行为负责，供应商不得以任何理由要求单方面更改买卖双方已达成合意的商品价格</span></u></b><span>；</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>（</span>4<span>）供应商应当准确填写商品信息，供应商应当保证并对其提供的商品信息的时效性、有效性、真实性、合法性和准确性上的瑕疵独自承担法律责任</span><b><u><span>；</span></u></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>（</span><span>5</span><span>）供应商挂货的商品在任何方面均不得造成买家对佛山南海铝拍拍科技有限公司提出任何合理合法的索赔主张，若因此给佛山南海铝拍拍科技有限公司造成任何损失的，供应商应当予以赔偿。</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>8</span></b><b>.<span>【对所挂货商品的特殊说明】</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>供应商可以在挂货时在</span>“<span>特殊说明</span><span>”</span><span>一栏对其挂货的商品信息进行额外的备注。</span><b><u><span>但若供应商的备注被铝拍拍平台据其自由裁量合理地认定为不清晰、不明确、不完整以至于影响到买家对供应商意思表示充分理解的，视作为供应商未做出相关意思表示，供应商填写的特殊说明无效。供应商对此予以认可和同意。</span></u></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><u><span>供应商填写的特殊说明属于以下情形之一的，属于违反铝拍拍平台挂货规定，铝拍拍平台有权据其自由裁量合理地认定供应商做出的特殊说明无效，供应商对此予以认可和同意：</span></u></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>（</span>1<span>）备注所售商品不受理质量异议，但未清晰完整地阐明不受理质量异议的具体范围、程度、原因等而损害买家权益的；</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>（</span>2<span>）对于已加工过的商品（加工指进行熔铸</span>、挤压、切割<span>等），加工后的商品质量情况与商品的产品质保书或质量</span>证<span>明书有差异的，但未就具体差异和差异的原因进行详尽说明而损害买家权益的；</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>（</span>3<span>）与该商品挂货信息不一致的；</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>（</span>4<span>）不合理加重买家义务或减损买家权利的；</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>（</span>5<span>）违反法律法规强制性规定的；</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>（</span>6<span>）包含营销广告类信息，或违反《中华人民共和国广告法》及其他对商品信息发布有特别规定的法律法规的；</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>（</span>7<span>）包含任何不能够实现的承诺的；</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>（</span>8<span>）被铝拍拍平台合理认为影响到买家对供应商意思表示充分理解的（例如存在影响或可能影响买家理解的用词、用语、用句）；和</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>（</span>9<span>）其他被铝拍拍平台合理认定为不适合作为商品备注在铝拍拍平台公示的。</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>第二节</span> <span>供应商等级划分</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>9.1</span><span>供应商等级共分为四个等级：</span><span>A</span><span>、</span><span>AA</span><span>、</span><span>AAA</span><span>、</span><span>AAAA</span><span>，根据不同等级授予不同的权限。</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>其中</span>，A<span>级</span>权限<span>开设店铺，在店铺里挂货</span>。
          </p>
          <p class="MsoNormal" style="text-indent:31.5pt;background:#FFFFFF;">
            AA<span>级权限</span><span>（</span><span>1</span><span>）开设店铺，在店铺里挂货，（</span><span>2</span><span>）参与</span><span>竞买报价</span>；
          </p>
          <p class="MsoNormal" style="text-indent:31.5pt;background:#FFFFFF;">
            AAA<span>级权限</span>（1<span>）开设店铺，在店铺里挂货</span>，<span>（</span>2<span>）参与竞买报价 </span><span>，（</span><span>3</span><span>）</span><span>发布竞卖</span>；
          </p>
          <p class="MsoNormal" style="text-indent:31.5pt;background:#FFFFFF;">
            AAAA<span>级权限</span>（1<span>）开设店铺，在店铺里挂货，（</span><span>2</span><span>）参与竞买报价 ，（</span><span>3</span><span>）发布竞卖</span><span>，（</span><span>4</span><span>）</span><span>发布竞标</span>；
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>9.2</span><span>等级越高代表供应商的交易情况和信用水平越好，较高的等级可以使供应商获得更多的会员特权，帮助供应商在</span>铝<span>拍拍平台获得更高效、更优质的服务。供应商的等级为每月更新一次。</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>10</span></b><b><span>【评级依据：会员分】</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>会员分是</span>铝拍拍<span>平台对供应商等级划分的依据，是根据供应商在供应商平台的行为作出的，动态变化的评估。会员分最高为</span>100<span>分，最低为</span><span>0</span><span>分。会员分包括基础分、经营分、履约分三项。具体的会员分与等级的对应标准如下：</span>
          </p>
          <table class="MsoNormalTable" border="1" cellspacing="0" style="width:100%;border:none;">
            <tbody>
              <tr>
                <td valign="center" style="border:1.5000pt solid #CCCCCC;background:#CCCCCC;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <b><span style="font-family:Roboto;color:#333333;font-size:9pt;">等级</span></b><b><span style="font-family:Roboto;color:#333333;font-size:9pt;"></span></b>
                  </p>
                </td>
                <td valign="center" style="border:1.5000pt solid #CCCCCC;background:#CCCCCC;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <b><span style="font-family:Roboto;color:#333333;font-size:9pt;">评价<span>&nbsp;</span></span></b><b><span style="font-family:Roboto;color:#333333;font-size:9pt;"></span></b>
                  </p>
                </td>
                <td valign="center" style="border:1.5000pt solid #CCCCCC;background:#CCCCCC;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <b><span style="font-family:Roboto;color:#333333;font-size:9pt;">分数区间</span></b><b><span style="font-family:Roboto;color:#333333;font-size:9pt;"></span></b>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="center" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:宋体;color:#333333;font-size:9.0000pt;">游客</span><span style="font-family:Roboto;color:#333333;font-size:9.0000pt;"></span>
                  </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:Roboto;color:#333333;font-size:9.0000pt;">尚未认证</span><span style="font-family:Roboto;color:#333333;font-size:9.0000pt;"></span>
                  </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:Roboto;color:#333333;font-size:9.0000pt;">未完成供应商资料认证</span><span style="font-family:Roboto;color:#333333;font-size:9.0000pt;"></span>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="center" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:宋体;color:#333333;font-size:9.0000pt;"><span>A</span></span><span style="font-family:Roboto;color:#333333;font-size:9.0000pt;"></span>
                  </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:宋体;color:#333333;font-size:9.0000pt;">表现</span><span style="font-family:Roboto;color:#333333;font-size:9.0000pt;">一般</span><span style="font-family:Roboto;color:#333333;font-size:9.0000pt;"></span>
                  </p>
                </td>
                <td valign="top" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:等线;font-size:10.5000pt;">30</span><span style="font-family:等线;font-size:10.5000pt;">-</span><span style="font-family:等线;font-size:10.5000pt;">42</span><span style="font-family:等线;font-size:10.5000pt;"></span>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="center" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:宋体;color:#333333;font-size:9.0000pt;"><span>AA</span></span><span style="font-family:Roboto;color:#333333;font-size:9.0000pt;"></span>
                  </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:Roboto;color:#333333;font-size:9.0000pt;">表现</span><span style="font-family:宋体;color:#333333;font-size:9.0000pt;">良好</span><span style="font-family:Roboto;color:#333333;font-size:9.0000pt;"></span>
                  </p>
                </td>
                <td valign="top" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:等线;font-size:10.5000pt;">42</span><span style="font-family:等线;font-size:10.5000pt;">-</span><span style="font-family:等线;font-size:10.5000pt;">57</span><span style="font-family:等线;font-size:10.5000pt;"></span>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="center" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:宋体;color:#333333;font-size:9.0000pt;"><span>AAA</span></span><span style="font-family:Roboto;color:#333333;font-size:9.0000pt;"></span>
                  </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:Roboto;color:#333333;font-size:9.0000pt;">表现</span><span style="font-family:宋体;color:#333333;font-size:9.0000pt;">优秀</span><span style="font-family:Roboto;color:#333333;font-size:9.0000pt;"></span>
                  </p>
                </td>
                <td valign="top" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:等线;font-size:10.5000pt;">67-83</span><span style="font-family:等线;font-size:10.5000pt;"></span>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="center" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:宋体;color:#333333;font-size:9.0000pt;"><span>AAAA</span></span><span style="font-family:Roboto;color:#333333;font-size:9.0000pt;"></span>
                  </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:Roboto;color:#333333;font-size:9.0000pt;">明星供应商</span><span style="font-family:Roboto;color:#333333;font-size:9.0000pt;"></span>
                  </p>
                </td>
                <td valign="top" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:等线;font-size:10.5000pt;">84-100</span><span style="font-family:等线;font-size:10.5000pt;"></span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>11</span></b><b>.<span>【基础分】</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>基础分满分为</span>30<span>分。基础分根据供应商的认证情况进行评估。供应商上传全部认证资料后即可获得</span><span>30</span><span>分。具体为：</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>11</span></b><b>.1</b>&nbsp;<span>供应商上传电子版营业执照可获得</span><span>10</span><span>分；</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>11</span></b><b>.2</b>&nbsp;<span>供应商上传法定代表人身份证件可获得</span><span>10</span><span>分；</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>11</span></b><b>.3</b>&nbsp;<span>供应商上传开具的增值税专用发票、一般纳税人信息，或税务部门提供的认证文件，获得纳税人资格认证可获得</span><span>5</span><span>分。</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>12</span></b><b>.<span>【经营分】</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>经营分满分为</span>70<span>分。经营分主要综合考量供应商的交易行为各项指标，通过数学模型分析后得出分数。主要考量因素包括销售频率、销售量等因素。经营分会根据供应商的交易行为每月更新。</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>13</span></b><b>.<span>【履约分】</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>履约分为附加分值。</span>铝<span>拍拍平台根据供应商的履约情况，在供应商基础分、经营分的基础上进行附加的加分或减分。</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>13</span></b><b>.1</b>&nbsp;<span>当供应商发生履约分减分事项后，</span>铝<span>拍拍平台将会对其经营分进行相应扣减。</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>供应商的履约分减分事项具体如下：</span>
          </p>
          <table class="MsoNormalTable" border="1" cellspacing="0" style="width:100%;border:none;">
            <tbody>
              <tr>
                <td valign="center" style="border:1.5000pt solid #CCCCCC;background:#CCCCCC;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <b><span style="font-family:Roboto;color:#333333;font-size:9pt;">事项</span></b><b><span style="font-family:Roboto;color:#333333;font-size:9pt;"></span></b>
                  </p>
                </td>
                <td valign="center" style="border:1.5000pt solid #CCCCCC;background:#CCCCCC;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <b><span style="font-family:Roboto;color:#333333;font-size:9pt;">分值</span></b><b><span style="font-family:Roboto;color:#333333;font-size:9pt;"></span></b>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="center" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:Roboto;color:#333333;font-size:9.0000pt;">订单违约</span><span style="font-family:Roboto;color:#333333;font-size:9.0000pt;"></span>
                  </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:Roboto;color:#333333;font-size:9.0000pt;">每单减<span>5</span><span>分</span></span><span style="font-family:Roboto;color:#333333;font-size:9.0000pt;"></span>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="center" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:Roboto;color:#333333;font-size:9.0000pt;">虚假挂货信息（篡改牌号、质量等级、包装信息等）</span><span style="font-family:Roboto;color:#333333;font-size:9.0000pt;"></span>
                  </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:Roboto;color:#333333;font-size:9.0000pt;">每单减<span>30</span><span>分</span></span><span style="font-family:Roboto;color:#333333;font-size:9.0000pt;"></span>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="center" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:Roboto;color:#333333;font-size:9.0000pt;">拖欠发票</span><span style="font-family:Roboto;color:#333333;font-size:9.0000pt;"></span>
                  </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:Roboto;color:#333333;font-size:9.0000pt;">每次减<span>10</span><span>分</span></span><span style="font-family:Roboto;color:#333333;font-size:9.0000pt;"></span>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="center" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:Roboto;color:#333333;font-size:9.0000pt;">质量异议处理周期超过<span>45</span><span>个工作日</span></span><span style="font-family:Roboto;color:#333333;font-size:9.0000pt;"></span>
                  </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:Roboto;color:#333333;font-size:9.0000pt;">每单减<span>5</span><span>分</span></span><span style="font-family:Roboto;color:#333333;font-size:9.0000pt;"></span>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="center" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:Roboto;color:#333333;font-size:9.0000pt;">成交转化率在被警示后仍为<span>0</span></span><span style="font-family:Roboto;color:#333333;font-size:9.0000pt;"></span>
                  </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:Roboto;color:#333333;font-size:9.0000pt;">减<span>30</span><span>分</span></span><span style="font-family:Roboto;color:#333333;font-size:9.0000pt;"></span>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="center" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:Roboto;color:#333333;font-size:9.0000pt;">质量异议处理供应商不配合</span><span style="font-family:Roboto;color:#333333;font-size:9.0000pt;"></span>
                  </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:Roboto;color:#333333;font-size:9.0000pt;">每单减<span>15</span><span>分</span></span><span style="font-family:Roboto;color:#333333;font-size:9.0000pt;"></span>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="center" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:Roboto;color:#333333;font-size:9.0000pt;">质量异议处理供应商主动违约撤单</span><span style="font-family:Roboto;color:#333333;font-size:9.0000pt;"></span>
                  </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:Roboto;color:#333333;font-size:9.0000pt;">每单减<span>15</span><span>分</span></span><span style="font-family:Roboto;color:#333333;font-size:9.0000pt;"></span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>供应商履约分减分事项中的</span>“<span>成交转化率</span><span>”</span><span>是指供应商在</span>铝<span>拍拍平台上投放的货物转化为成交订单的比例。供应商在</span>铝<span>拍拍平台上首次挂货满一（</span>1<span>）个月后的次月起，</span>铝<span>拍拍平台每月</span>1<span>日会对供应商的成交转化率进行评估（例如买家首次挂货日为</span><span>5</span><span>月</span><span>4</span><span>日，则</span>铝<span>拍拍平台会从</span>7<span>月</span><span>1</span><span>日起对供应商的成交转化率进行评估）。若供应商上月的成交转化率为</span><span>0</span><span>，且挂货量较大，挂货天数较多，则</span>铝<span>拍拍平台会警示供应商成交转化率为</span>0<span>。若该等被警示的供应商在当月</span><span>8</span><span>日的成交转化率仍然为</span><span>0</span><span>，即被判定为</span><span>“</span><span>成交转化率在被警示后仍为</span><span>0”</span><span>，该供应商的履约分减去</span><span>30</span><span>分。</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>13</span></b><b>.2</b>&nbsp;<span>供应商的履约分加分仅限于用于抵销履约分减分。即当供应商的履约分由于发生履约分减分事项而被扣减后，如供应商发生履约分加分事项，则</span>铝<span>拍拍平台会对其经营分进行相应增加。在该等机制下，供应商履约分加分后的上限为其当月被评估得出的经营分。</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>供应商的履约分加分事项具体如下：</span>
          </p>
          <table class="MsoNormalTable" border="1" cellspacing="0" style="width:100%;border:none;">
            <tbody>
              <tr>
                <td valign="center" style="border:1.5000pt solid #CCCCCC;background:#CCCCCC;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <b><span style="font-family:Roboto;color:#333333;font-size:9pt;">事项</span></b><b><span style="font-family:Roboto;color:#333333;font-size:9pt;"></span></b>
                  </p>
                </td>
                <td valign="center" style="border:1.5000pt solid #CCCCCC;background:#CCCCCC;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <b><span style="font-family:Roboto;color:#333333;font-size:9pt;">分值</span></b><b><span style="font-family:Roboto;color:#333333;font-size:9pt;"></span></b>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="center" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:Roboto;color:#333333;font-size:9.0000pt;">销售订单完成</span><span style="font-family:Roboto;color:#333333;font-size:9.0000pt;"></span>
                  </p>
                </td>
                <td valign="center" style="border:1.0000pt solid #CCCCCC;background:#FFFFFF;">
                  <p class="MsoNormal" align="center" style="text-align:center;">
                    <span style="font-family:Roboto;color:#333333;font-size:9.0000pt;">每单加<span>1</span><span>分</span></span><span style="font-family:Roboto;color:#333333;font-size:9.0000pt;"></span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b>1</b><b><span>4</span></b><b>.<span>【评级机制的变更】</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            柏<span>斯基有权将供应商评级规则的评级机制（包括但不限于：供应商等级所对应的会员分分数区间、经营分构成、履约分的加减分项等）根据评级数学模型、平台业务等原因而不时变更。</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b>&nbsp;</b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>第三节</span> &nbsp;<span>交易模式与合同生成</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>15</span></b><b>. <span>【</span></b><b>交易模式的分类</b><b><span>】</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>15</span></b><b>.</b><b><span>1</span></b><b>【普通订单模式】</b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>买家可以在供应商默认的营业时间（一般为工作日北京时间</span><span>11:00</span><span>—</span><span>17:00</span><span>）或者供应商根据平台选项目自行设置的其他营业时间将供应商在铝拍拍平台挂货的可订货商品加入购物车并生成订单。在非营业时间，买家仅可浏览相关的挂货信息，无法将商品加入购物车，亦无法生成订单。</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>15.2</span><span>【</span><b>竞卖模式</b>】
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>在铝拍拍竞卖中心，</span> <span>供应商发布竞卖产品，起拍价为最高价格，系统按照供应商设置的交易结束时间及时间间隔进行降价拍卖，任一买家在显示的结束时间前参与竞卖，按照系统显示的当前价格报价，则竞卖成功，生成竞卖订单。</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>15.3</span><span>【</span><b>竞买模式</b>】
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            在铝拍拍竞买中心，买家发布竞买商品，起拍价为买家接受的最高价格，供应商可在系统显示的交易结束时间内多次报价，直到竞买时间结束，但每次报价不能高出买家接受的最高价格及上一轮次任一供应商的报价，在显示的结束时间前最后参与竞买的供应商报价，则竞买成功，生成竞买订单。
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>15.4</span><span>【竞标模式】</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            在铝拍拍竞标中心，买家发布竞标合同产品清单，竞标合同可以包含若干产品或者服务，起拍价为买家接受的竞标合同最高价格，供应商可在系统显示的交易结束时间内多次报价，直到竞标时间结束，但每次报价不能高出买家接受的最高价格及上一轮次任一供应商的报价，在显示的结束时间前最后参与竞标的供应商报价，则竞标成功，生成竞买订单。
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>16</span></b><b>.<span>【生成订单】</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>16.1</span><span>在</span>普通<span>订单模式，买家对购物车内拟采购商品的全部信息确认无误后，可以在可交易期间内将购物车中的商品生成订单。</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>16.2</span><span>在竞</span>卖<span>模式下</span>，买家在竞卖结束前报价，则竞卖成功，生成竞卖订单。
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            以上两种模式<b><u><span>订单生成前，买家应当预览并可以打印拟生成的合同样本，该样本中全部条款与最终待达成的买卖合同完全一致，列明了买家和供应商作为买卖双方具体的权利义务，买家应当在对拟生成的合同中全部条款的充分理解和完全同意的基础上确认生成订单。</span></u></b><span>订单生成后，买家选定的商品即被锁定，其他买家无法购买该订单内的任何商品。买家生成订单的，买卖合同随即成立并生效，</span> <span>买家可以自行打印买卖合同，该买卖合同系根据拟生成的合同样本生成的合法有效的电子文本合同，该合同在铝拍拍平台留存</span>。
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>16.3</span><span>在</span>竞买和竞标<span>模式下，</span>供应商对<span>拟供应商品的全部信息确认无误后，系统</span><span>在显示的结束时间前最后参与竞买</span><span>/</span><span>竞标的供应商报价，则竞买</span><span>/</span><span>竞标成功，生成竞买</span><span>/</span><span>竞标订单</span><span>生成订单，</span>卖<span>家生成订单的事实行为即构成对</span>买<span>家</span>购买<span>要约行为的承诺，买家和供应商之间的商品买卖的事实合同成立并生效。铝拍拍平台届时将向买卖双方发送成交短信通知。提示买卖双方尽快在平台签署合同</span>。<span>买卖双方应当各自承担其在商品买卖合同项下的权利和义务</span>。买卖双方可以自行打印买卖合同，该买卖合同系合法有效的电子文本合同，该合同在铝拍拍平台留存。
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b>&nbsp;</b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>第</span></b><b>三节</b><b>&nbsp;<span>附则</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>17</span></b><b>.<span>【通知】</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            柏<span>斯基可以通过电子邮件、手机短信、传真、</span>APP<span>推送、铝拍拍平台即时通讯工具的方式向用户在铝拍拍平台注册时提供的电子邮件地址、手机号码、传真号进行通知。通过电子邮件、手机短信、</span><span>APP</span><span>推送、铝拍拍平台即时通讯工具方式进行通知，送达时间以相关电子邮件、手机短信、推送或通讯内容在</span>柏<span>斯基系统中记载的发出时间为准；通过传真方式进行通知，送达时间为相关传真的发出时间。</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><u><span>同时，</span></u></b><b><u><span>柏</span></u></b><b><u><span>斯基也有权通过铝拍拍平台以公告的方式通知买家与任何铝拍拍平台项下产品或服务有关的任何事宜，用户有义务不时关注铝拍拍平台的公告信息。公告或通知与本协议不一致的，以公告或通知的内容为准。</span></u></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>18</span></b><b>.<span>【违规】</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><u><span>当买家违反本规则，破坏铝拍拍平台交易秩序或对铝拍拍平台经营管理造成不利影响的，买家理解并确认，铝拍拍平台有权对买家采取包括但不限于限制开票、评级降级、冻结账号等措施，若造成铝拍拍平台造成损失的，铝拍拍平台有权向买家追索。</span></u></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>19</span></b><b>.<span>【保密】</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>买家向</span>柏<span>斯基承诺，其或其雇员、代理或顾问将对因任何在铝拍拍平台交易项下而收到或接触到的信息严格保密，前述信息包括任何特定交易项下的商业条款和条件、促成交易和履行交易的任何沟通、以及其他与交易有关的信息；买家在今后的任何时候均不会使用、泄露或透露给任何人该等信息并应尽最大努力防止任何此类信息的发布或泄露。但前述限制不适用于买家因法律、监管机构或政府主体要求而做出的任何披露或为获得建议而向任何专业顾问所做出的任何披露，也不适用于非因佛山南海铝拍拍科技有限公司或买家对本条的违反而进入公共领域的任何信息。</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>20</span></b><b>.<span>【电子印章】</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>铝拍拍平台有权要求符合条件的买家在特定交易场景下使用电子印章的形式签署该交易项下的合同，买家同意并在该合同上加盖电子印章的，视为买家对该合同的妥为签署，加盖电子印章的电子合同与加盖实物印章的纸质合同具有同等法律效力，买家不得因其采用电子印章的形式而否定其签署的合同的法律效力。</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>21</span></b><b>.<span>【排除不利于起草方的解释原则】</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>本规则应以字面意思进行解释。在法律法规允许的范围内，以不利于起草方的解释排除歧义的原则在解释本规则时不被采用。</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>22</span></b><b>.<span>【权利可累计性；放弃】</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>本协议项下双方所享有的权利是可累计的并且所涉主体均可按照其认为适当的频率行使其权利。除非明示放弃或书面变更，本协议项下任一方所享有的权利不可被放弃或者改变。针对前述权利的任何不行使或者延迟行使均不应被视为对该权利或其他权利的放弃或者变更。针对前述权利的任何瑕疵行使和部分行使均不应排除对该等权利或其他权利的进一步或者其他行使。任何一方的任何作为、行为或者谈判均不得以任何方式阻碍该方行使任何此类权利或构成任何此类权利的中止或变更。</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>23</span></b><b>.<span>【可分割性】</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>如果本规则项下任何一项或者多项条款因任何原因被认定为无效或不可执行，该一项或多项条款应被视为与本规则项下的其他规定内容相分割，并且前述无效或不可执行的条款在任何情况下均不应对本规则项下其他条款的效力和执行力产生影响，也不得影响本规则所涉任何一方所享有的权利。在法律允许的范围内，本规则所涉任何一方特此放弃适用任何使得本规则任何规定在任何方面无效或不可执行的任何法律规定的权利。</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>24</span></b><b>.<span>【法律适用及争议解决】</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><u><span>本规则的成立、生效、履行、解释及纠纷解决，适用中华人民共和国法律（不包括其法律适用法）。</span></u></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>买家和</span>柏<span>斯基因本规则产生的，或与本规则相关的任何纠纷或争议，首先应友好协商解决。协商不成的，</span><b><u><span>有关方应当将纠纷或争议提交至</span></u></b><b><u><span>佛山</span></u></b><b><u><span>市佛山南海铝拍拍科技有限公司企业注册所在地的人民法院通过诉讼的方式解决。</span></u></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <b><span>25</span></b><b>.<span>【规则的生效与变更】</span></b><b></b>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>本规则于</span>2021<span>年</span>&nbsp;&nbsp;<span>月</span>&nbsp;&nbsp;&nbsp;<span>日公示，公示期为</span>7<span>日，公示期满后于</span><span>2021</span><span>年</span>&nbsp;&nbsp;<span>月</span>&nbsp;&nbsp;&nbsp;<span>日实施生效。在符合《电子商务法》或其他适用法律规定的公示要求或其他强制性要求的前提下，</span>柏<span>斯基有权根据需要不时地重述、修改本规则，并以在铝拍拍平台公告的方式通知买家。</span><b><u><span>如不同意相关变更的，买家必须立即停止任何使用铝拍拍平台的行为。买家继续使用铝拍拍平台的行为即构成买家对公告及所涉相关规则变更（无论该等规则是否以弹窗形式单独要求买家确认）的无条件确认与接受。</span></u></b><span>变更生效后的本规则对该等规则变更生效前各方发生的行为或该等行为产生的法律后果均不具有溯及力。</span>
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>版本</span> 1.0
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            <span>生效日期</span> 2021-0<span>7</span>-<span>27</span>
          </p>
          <p class="MsoNormal">
            &nbsp;
          </p>
          <p class="MsoNormal" style="background:#FFFFFF;">
            &nbsp;
          </p>
        </div>
      </div>

      <div style="width: 200px; margin: 20px auto 0; padding-bottom: 20px">
        <div>
          <el-checkbox v-model="checked"
            >同意《供应商交易规则》</el-checkbox
          >
        </div>
        <el-button
          type="primary"
          :disabled="!checked"
          style="margin: 10px 0 0 60px"
          @click="applySupplier"
          >申请入驻</el-button
        >
      </div>
    </div>

    <div v-if="supplierStatus === 2" style="height: 300px; position: relative">
      <div style="position: absolute; left: 46%; top: 30%; text-align: center">
        <i
          class="el-icon-success"
          style="font-size: 50px; color: #55c176; margin-left: -12px"
        ></i>
        <p style="margin: 10px 0 0; font-weight: bold; font-size: 20px">
          你已是供应商!
        </p>
        <div>
          <el-button type="text" @click="$router.go(-1)">点击返回</el-button>
        </div>
      </div>
    </div>

    <div
      v-if="supplierStatus === 1"
      style="text-align: center; height: 300px; position: relative"
    >
      <div style="position: absolute; left: 46%; top: 30%; text-align: center">
        <i
          class="el-icon-warning"
          style="font-size: 50px; color: #f9bb1d; margin-left: -4px"
        ></i>
        <p style="margin: 10px 0 0; font-weight: bold; font-size: 20px">
          申请已提交，正在审批中!
        </p>
        <div>
          <el-button type="text" @click="$router.go(-1)">点击返回</el-button>
        </div>
      </div>
    </div>

    <div
      v-if="supplierStatus === 3"
      style="text-align: center; height: 300px; position: relative"
    >
      <div style="position: absolute; left: 46%; top: 30%; text-align: center">
        <i
          class="el-icon-error"
          style="font-size: 50px; color: #fe2321; margin-left: -4px"
        ></i>
        <p style="margin: 10px 0 0; font-weight: bold; font-size: 20px">
          申请已被拒绝!
        </p>
        <div>
          <el-button type="text" @click="$router.go(-1)">点击返回</el-button>
          <el-button type="text" @click="supplierStatus = 0"
            >重新申请</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apply, checkSupplier } from "./api";
export default {
  data() {
    return {
      checked: false,
      supplierStatus: 0,
    };
  },
  created() {
    this.check();
  },
  methods: {
    applySupplier() {
      apply().then((res) => {
        if (res.errno == 0) {
          this.$notify({
            title: "供应商入驻申请",
            message: "成功",
            type: "info",
            duration: 1500,
          });
          this.check();
        }
      });
    },
    check() {
      checkSupplier().then((res) => {
        this.supplierStatus = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import '../Doc/components/style.less';

.part {
  margin-bottom: 10px;
}
</style>
